<template>
    <div class="mt10" v-for="(item,index) in list" :class="[item.type==1 ?'flex-end':'flex-start']" :key="index">
        <div class="msg-item p10 font14" :class="[item.type==1 ?'send-item mr20':'receive-item ml20']">{{item.msg}}</div>
    </div>
    <div class="input-dom py10">
        <div class="flex">
            <el-input class="ml30" v-model="msg" size="small"></el-input>
            <el-button class="ml20" type="primary" size="small" @click="send_msg">发送</el-button>
        </div>
    </div>
</template>

<script>
  import {send_data} from "@net/chat/chat_dom";

  let timer = null
  let times = null
  export default {
    name: "chat_room_dom",
    data() {
      return {
        ws: null,
        ws_url: 'wss://www.lmlg.top:8000',
        from_id: '',//发送人ID
        receive_id: '',//接收人ID
        list: [],
        msg: ''
      }
    },
    created() {
      if (this.Utils.has_login()) {//赋予身份
        this.from_id = this.Utils.public_var('openid');
        this.receive_id = 'temporary_user';
      } else {
        this.from_id = 'temporary_user';
        this.receive_id = '7bf69fd331f50cc68151dd5964ac324a';
      }
    },
    methods: {
      ws_open(res) {
        console.log('open')
        this.heartCheck()
      },
      ws_message(res) {
        //console.log(res)
        let data = JSON.parse(res.data);
        //console.log(typeof(data))
        if (typeof (data) != 'string') {
          if (data.type == 'init') {//绑定用户与client_id
            let bind = {"type": 'bind', "fromid": this.from_id};
            this.ws.send(JSON.stringify(bind));
          } else if (data.type == 'heart') {
            this.heartCheck()//发送心跳
          } else if (data.type == 'new_msg') {
            this.list.push({type: 2, msg: data.msg})
          }
        }
      },
      ws_error(res) {
        console.log('websocket Connection error.');
      },
      ws_close(res) {
        console.log('websocket Connection Closed.');
      },
      heartCheck() {
        if (timer) {
          clearTimeout(timer);
        }
        if (times) {
          clearTimeout(times);
        }
        let self = this
        timer = setTimeout(() => {
          self.ws.send(JSON.stringify({"type": 'heart'}));
          times = setTimeout(() => {//没有收到心跳回应，则关闭
            self.ws.close();
            clearTimeout(timer);
            clearTimeout(times);
          }, 20000)
        }, 20000)
      },
      send_msg() {
        let obj = {}
        if (this.msg == '') {
          this.$toast('请输入内容')
          return
        }
        obj.msg = this.msg
        obj.receive_id = this.receive_id
        this.Utils.debounce(() => {
          send_data(obj).then(res => {
            if (res.business_code == 200) {
              this.list.push({type: 1, msg: this.msg})
              this.msg = ''
            } else {
              this.$toast({message: res.msg, className: 'toast-vant'})
            }
          })
        })
      }
    },
    mounted() {
      if (window.WebSocket) {
        this.ws = new WebSocket(this.ws_url)
        let that = this
        this.ws.onopen = function (res) {
          that.ws_open(res)
        }
        this.ws.onmessage = function (event) {
          that.ws_message(event)
        }

        //发生错误
        this.ws.onerror = function (event) {
          that.ws_error(event)
        }
        this.ws.onclose = function (event) {
          that.ws_close(event)
        }
      } else {
        alert('该浏览器不支持web socket');
      }

    },
    beforeCreate() {//设置样式

      document.querySelector('body').setAttribute('style', 'background:#f4f4f4');
    },
    beforeUnmount() {//移除样式
      document.querySelector('body').removeAttribute('style')
    }
  }
</script>

<style lang="scss" scoped>
    .input-dom {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 60px;
        border-top: 1px solid #ddd;
        box-shadow: 2px -1px 1px 2px #f8f8f8;

        ::v-deep(.el-input) {
            width: 250px;
        }
    }

    .msg-item {
        line-height: 20px;
        border-radius: 5px;
        color: #333;
    }

    .send-item {
        background-color: #55a532;
    }

    .receive-item {
        background-color: white;
    }
</style>